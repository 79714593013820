@import url(https://p.typekit.net/p.css?s=1&k=tqd8ygq&ht=tk&f=10294.10296.10304&a=62062026&app=typekit&e=css);
@import url(https://p.typekit.net/p.css?s=1&k=ffl1tvr&ht=tk&f=139.175.176&a=62062026&app=typekit&e=css);
@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/1da05b/0000000000000000000132df/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/8f4e31/0000000000000000000132e3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "brandon-grotesque";
  src: url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("woff"),
    url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n9&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 900;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("woff"),
    url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3")
      format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "proxima-nova";
  src: url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff2"),
    url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("woff"),
    url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3")
      format("opentype");
  font-display: auto;
  font-style: italic;
  font-weight: 400;
}

body {
  margin: 0;
  font-family: "brandon-grotesque", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 2.5rem;
  font-size: 1.5rem;
}
h1,
h2 {
  font-size: 4rem;
  line-height: 3.5rem;
  font-family: "brandon-grotesque";
  font-weight: 900;
  color: #4a73b2;
}
h2 {
  color: #2b2b2b;
  font-size: 2rem;
  line-height: 2rem;
}
h3 {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 900;
  margin-top: 2rem;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
img {
  max-width: 100%;
}

a:link,
a:visited {
  color: #2ea3f2;
  text-decoration: none;
}

.Collapsable_container__3yksG {
  margin: 0 0 1rem 0;
}

.Collapsable_title__3aKDL {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Collapsable_collapsed__2adVV {
  display: none;
}

.Collapsable_uncollapsed__2ihT4 {
  display: block;
}

.IconButton_buttonBorderless__9dEku,
.IconButton_button__29vBa {
  z-index: 1;
  text-align: center;
  color: #999999;
  padding: .5rem;
  background-color: #d0d0d0;
  border-radius: 0.3rem;
  border: 1px solid #00000022;
  width: 1rem;
  height: 1rem;
  line-height: 1rem;
  margin: -0.1rem 0.2rem 0 0.2rem;
}

.IconButton_buttonBorderless__9dEku {
  color: white;
  padding:.2rem;
}

.IconButton_buttonBorderless__9dEku {
  background-color: transparent;
  border: 0px;
}

.IconButton_buttonBorderless__9dEku:hover,
.IconButton_button__29vBa:hover {
  opacity: 0.7;
}

.IconButton_disabled__KmwV1,
.IconButton_disabled__KmwV1:hover {
  color: #00000022;
  opacity: 1;
}

.IconButton_alternate__2UPpH {
  color: #54818c;
}

.TagFilter_container__1-uv4 {
  text-align: left;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.TagFilter_content__28beP {
  border-radius: 0.3rem;
  position: relative;
  padding: 1rem 2rem 2rem 2rem;
  z-index: 1;
  overflow: scroll;
  height: 80vh;
  width: 75vw;
  max-height: 30rem;
  max-width: 20rem;
  background-color: #ffffff;
  box-shadow: -1px 0px 7px #000000;
}
.TagFilter_header__1DkjW {
  font-weight: 900;
  font-size: 1.4rem;
  text-align: center;
  margin-top: 0.5rem;
}
.TagFilter_col__2mU2y {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.TagFilter_row__JtDfY {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.TagFilter_checkRow__3i2_j {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 17rem;
}
.TagFilter_button__3U4WW,
.TagFilter_buttonReset__14vWT {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  justify-content: center;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 0.5rem;
  margin: 0 0.5rem;
  width: 5rem;
  min-width: 5rem;
  min-height: 3rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1rem;
}
.TagFilter_buttonReset__14vWT {
  background-color: #cccccc;
}
.TagFilter_icon__1QVYe {
  width: 2rem;
  margin: 0 1rem 0.1rem 1rem;
}
.TagFilter_checked__2kaea,
.TagFilter_unchecked__1mTb6 {
  border: 1px solid #cccccc;
  background-color: rgb(255, 255, 255);
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0.5rem;
}
.TagFilter_checked__2kaea:before {
  position: relative;
  content: "\2713";
  top: -0.5rem;
  left: 0.2rem;
  color: #3b69aa;
  font-weight: 900;
}
.TagFilter_unchecked__1mTb6 {
  background-color: #e4e4e4;
}
.TagFilter_display__2vopf {
  font-size: 1.4rem;
  line-height: 1.2rem;
  width: 11rem;
}

