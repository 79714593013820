.container {
  text-align: left;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  border-radius: 0.3rem;
  position: relative;
  padding: 1rem 2rem 2rem 2rem;
  z-index: 1;
  overflow: scroll;
  height: 80vh;
  width: 75vw;
  max-height: 30rem;
  max-width: 20rem;
  background-color: #ffffff;
  box-shadow: -1px 0px 7px #000000;
}
.header {
  font-weight: 900;
  font-size: 1.4rem;
  text-align: center;
  margin-top: 0.5rem;
}
.col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.checkRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  width: 17rem;
}
.button,
.buttonReset {
  display: flex;
  align-items: center;
  background-color: #eeeeee;
  justify-content: center;
  border: 1px solid black;
  border-radius: 1rem;
  padding: 0.5rem;
  margin: 0 0.5rem;
  width: 5rem;
  min-width: 5rem;
  min-height: 3rem;
  text-align: center;
  font-size: 1.2rem;
  line-height: 1rem;
}
.buttonReset {
  background-color: #cccccc;
}
.icon {
  width: 2rem;
  margin: 0 1rem 0.1rem 1rem;
}
.checked,
.unchecked {
  border: 1px solid #cccccc;
  background-color: rgb(255, 255, 255);
  height: 1.5rem;
  width: 1.5rem;
  margin: 0 0.5rem;
}
.checked:before {
  position: relative;
  content: "\2713";
  top: -0.5rem;
  left: 0.2rem;
  color: #3b69aa;
  font-weight: 900;
}
.unchecked {
  background-color: #e4e4e4;
}
.display {
  font-size: 1.4rem;
  line-height: 1.2rem;
  width: 11rem;
}
